import { Player } from 'components/Player/Player';
import { Display } from './components/Display/Display';
import { isDesktop } from 'react-device-detect';
import { useGame } from 'contexts/GameContext';
import { Center, Spinner } from '@chakra-ui/react';
export const Game: React.FC = () => {
  const { isLoadingGameState } = useGame();

  if (isLoadingGameState) {
    return (
      <Center height="100vh" width="100%" background="jeopardy.blue">
        <Spinner height="10vh" width="10vh" color="jeopardy.gold" thickness="0.5vh" />
      </Center>
    );
  }
  return isDesktop ? <Display /> : <Player />;
};
