import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Center,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useGame } from '../../contexts/GameContext';
import { EventTypes } from 'types';
import { TextFit } from 'TextFit';
import { every } from 'lodash';

export const CategorySelection: React.FC = () => {
  const { gameState, sendEvent, isActivePlayer } = useGame();
  if (!gameState) {
    return null;
  }
  const { categories } = gameState;

  const onClueSelect = ({ clueId, categoryId }: { clueId: string; categoryId: string }) => {
    sendEvent({ type: EventTypes.SELECT_CATEGORY, clueId, categoryId });
  };

  return isActivePlayer ? (
    <Accordion
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%"
      width="100%"
      allowToggle
    >
      {categories
        .filter((c) => c.roundType === gameState.currentRound)
        .map((category) => (
          <AccordionItem
            flexGrow={1}
            display="flex"
            flexDir="column"
            justifyContent="center"
            key={category.id}
            isDisabled={every(category.clues, (clue) => clue.answered)}
          >
            <AccordionButton flexGrow={1}>
              <TextFit
                max={3}
                style={{ textWrap: 'nowrap' }}
                as={Flex}
                justifyContent="flex-start"
                alignItems="center"
                textStyle="category"
              >
                {category.name}
              </TextFit>
            </AccordionButton>
            <AccordionPanel py="2em">
              <Flex gap="3px" justifyContent="space-around">
                {category.clues.map((clue) => (
                  <Button
                    height="0"
                    width="calc(100% / 6)"
                    pb="calc(100% / 6)"
                    borderColor="jeopardy.gold"
                    borderWidth="2px"
                    borderStyle="solid"
                    background="jeopardy.blue"
                    isDisabled={clue.answered}
                    key={clue.id}
                    onClick={() => onClueSelect({ clueId: clue.id, categoryId: category.id })}
                    position="relative"
                  >
                    <Center width="100%" position="absolute" top={0} right={0} bottom={0}>
                      <Text left={0} textStyle="value" fontSize="1.5em">
                        ${clue.value}
                      </Text>
                    </Center>
                  </Button>
                ))}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  ) : (
    <Text textStyle="clue">{gameState.activePlayer?.name} is selecting category</Text>
  );
};
