import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
        @font-face {
            font-family: 'Gameboard';
            src: url('./fonts/911.ttf') format('truetype');
        }
        @font-face {
            font-family: 'Clue';
            src: url('./fonts/Korinna.ttf') format('truetype');
        }
        @font-face {
            font-family: 'Jeoparty';
            src: url('./fonts/geoparody.otf') format('opentype');
        }
`}
  />
);

export default Fonts;
