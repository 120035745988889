import {
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { useGame } from 'contexts/GameContext';
import { useState } from 'react';
import { EventTypes, Rounds } from 'types';

export const CollectWager: React.FC = () => {
  const { isActivePlayer, currentPlayer, gameState, sendEvent } = useGame();
  const defaultMax = gameState?.currentRound === Rounds.Jeopardy ? 1000 : 2000;
  const playerHasEnough = currentPlayer!.score >= defaultMax;
  const [wager, setWager] = useState(playerHasEnough ? currentPlayer!.score / 2 : defaultMax / 2);
  const isDailyDouble = gameState?.activeClue?.isDailyDouble || false;

  const submitWager = () => {
    sendEvent({ type: EventTypes.SUBMIT_WAGER, wager, playerName: currentPlayer!.name });
  };

  const isFinalJeopardy = gameState?.currentRound === Rounds.FinalJeopardy;
  const title = !isFinalJeopardy ? 'Daily Double' : 'Final Jeopardy';
  const playerHasWagered = currentPlayer!.finalJeopardyWager !== null;

  if ((isFinalJeopardy && playerHasWagered) || (isDailyDouble && !isActivePlayer)) {
    return (
      <Text p="1em" textAlign="center" textStyle="clue" fontSize="3em">
        Waiting for other players to wager
      </Text>
    );
  }

  return (
    <Flex
      padding="10%"
      width="100%"
      flexDir="column"
      alignItems="center"
      justifyContent="flex-start"
      gap="20px"
    >
      <Text fontSize="3em" textStyle="jeoparty">
        {title}
      </Text>
      <Text textAlign="center" textStyle="category" fontSize="4em">
        ${wager.toLocaleString()}
      </Text>
      <Slider
        step={10}
        onChange={setWager}
        min={0}
        max={playerHasEnough ? currentPlayer!.score : defaultMax}
      >
        <SliderTrack borderRadius="500px" height="20px" bg="jeopardy.lightBlue">
          <SliderFilledTrack bg="jeopardy.clueBlue" />
        </SliderTrack>
        <SliderThumb outline="none" bg="transparent" border="none" boxSize={20}>
          <Text
            // textShadow="1px 1px 2px black"
            fontFamily="Jeoparty"
            fontSize="5em"
            color="jeopardy.gold"
          >
            $
          </Text>
        </SliderThumb>
      </Slider>
      <Button
        onClick={submitWager}
        height="80px"
        width="80%"
        bg="jeopardy.lightBlue"
        color="white"
        textStyle="clue"
      >
        <Text fontSize="2em" textAlign="center" textStyle="clue">
          Submit
        </Text>
      </Button>
    </Flex>
  );
};
