import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { BoardBox } from './BoardBox';
import { useGame } from '../../contexts/GameContext';
import { Clue, EventTypes, JeopardyStates } from 'types';
import {
  AnimatePresence,
  AnimationPlaybackControls,
  motion,
  useAnimate,
  useMotionValue,
} from 'framer-motion';
import { TextFit } from 'TextFit';
import { useEffect, useRef } from 'react';
import { useTimer } from 'react-timer-hook';

export type Props = {
  clue: Clue;
  renderDelay: number;
};

const timeToAnswer = 15;

export const ClueBox: React.FC<Props> = ({ clue, renderDelay }) => {
  const { gameState, sendEvent } = useGame();
  const isActive = gameState?.activeClue?.id === clue.id;
  const playerAnswering = gameState?.currentState === JeopardyStates.PlayerAnswering;
  const isActiveDailyDouble =
    isActive && gameState?.currentState === JeopardyStates.DailyDoubleWager;
  const wasLastClue = useRef(false);
  const { seconds, restart, pause, resume } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      if (isActive) {
        sendEvent({ type: EventTypes.TIME_UP });
      }
    },
  });
  const wasAnswering = useRef(false);
  const timerWidth = useMotionValue(0);
  const [scope, animate] = useAnimate();
  const animation = useRef<AnimationPlaybackControls | null>(null);

  useEffect(() => {
    timerWidth.set(((timeToAnswer - seconds) / timeToAnswer) * 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  useEffect(() => {
    if (gameState?.currentState === JeopardyStates.PlayerAnswering) {
      animation.current?.pause();
      pause();
      wasAnswering.current = true;
    } else if (wasAnswering.current) {
      resume();
      animation.current?.play();
      wasAnswering.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState?.currentState]);

  useEffect(() => {
    if (isActive && !isActiveDailyDouble) {
      restart(new Date(new Date().getTime() + timeToAnswer * 1000));
      animation.current = animate(
        scope.current,
        { width: '100%' },
        { duration: timeToAnswer, ease: 'linear' },
      );
      wasLastClue.current = true;
    } else if (wasLastClue.current) {
      wasLastClue.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  if (isActiveDailyDouble) {
    return (
      <Center position="relative">
        <motion.div
          style={{
            position: 'fixed',
            transformStyle: 'preserve-3d',
            background: 'blue',
            padding: '20px',
            zIndex: 100,
          }}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: [null, 1],
            top: [null, 0],
            right: [null, 0],
            bottom: [null, 0],
            left: [null, 0],
            rotateX: 1080,
          }}
          transition={{
            duration: 2,
          }}
        >
          <Center height="100%" width="100%">
            <Text textStyle="jeoparty" fontSize="30vh">
              Daily Double
            </Text>
          </Center>
        </motion.div>
      </Center>
    );
  }

  return (
    <BoardBox
      backgroundColor={isActive ? 'jeopardy.clueBlue' : 'inherit'}
      position={isActive ? 'fixed' : undefined}
      height={isActive ? '100vh' : undefined}
      width={isActive ? '100vw' : undefined}
      top="0"
      bottom="0"
      left="0"
      right="0"
      padding={isActive ? '50px' : '25px'}
      key={clue.id}
      zIndex={isActive ? 100 : undefined}
    >
      <AnimatePresence>
        {isActive ? (
          <Flex height="100%" width="100%" flexDirection="column" gap="20px" alignItems="center">
            {clue.isDailyDouble && playerAnswering && (
              <>
                <Text fontSize="3vh" textStyle="clue">
                  {gameState.activePlayer?.name}'s wager:
                </Text>
                <Text fontSize="5vh" textStyle="category">
                  ${gameState.dailyDoubleWager?.toLocaleString()}
                </Text>
              </>
            )}
            <TextFit p="3em" max={6} zIndex={102} as={Center} textStyle="clueActive">
              {clue.question}
            </TextFit>
          </Flex>
        ) : clue.answered ? (
          <TextFit
            as={Center}
            opacity={wasLastClue.current ? 1 : 0.3}
            textStyle="clue"
            textAlign="center"
          >
            {clue.answer}
          </TextFit>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2, delay: renderDelay * 0.5 }}
          >
            <Text as={motion.div} textStyle="clueValue">
              ${clue.value}
            </Text>
          </motion.div>
        )}
      </AnimatePresence>
      {isActive && (
        <Box
          width="0%"
          ref={scope}
          position="fixed"
          left={0}
          right={0}
          bottom={0}
          zIndex={101}
          height="5%"
          bg="jeopardy.lightBlue"
        />
      )}
    </BoardBox>
  );
};
