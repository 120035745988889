import { extendTheme } from '@chakra-ui/react';

const colors = {
  jeopardy: {
    blue: '#071277',
    clueBlue: '#0000FF',
    lightBlue: '#7498FF',
    gold: '#D69F4C',
  },
};

const theme = extendTheme({
  styles: {
    global: {
      html: {
        height: '100%',
      },
      body: {
        backgroundColor: 'jeopardy.blue',
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
    },
  },
  colors,
  textStyles: {
    category: {
      fontFamily: 'Gameboard',
      color: 'white',
      textTransform: 'uppercase',
      textShadow: '0.05em 0.05em 0 black',
    },
    clueValue: {
      fontFamily: 'Gameboard',
      fontSize: '12vh',
      color: 'jeopardy.gold',
      textShadow: '10px 10px 0 black',
    },
    value: {
      fontFamily: 'Gameboard',
      color: 'jeopardy.gold',
      textTransform: 'uppercase',
      textShadow: '2px 2px 0 black',
    },
    score: {
      fontGamily: 'Gameboard',
      color: 'white',
      fontSize: '4vh',
      fontWeight: 'bold',
      textShadow: '2px 2px 0 black',
    },
    clue: {
      fontFamily: 'Clue',
      color: 'white',
      textTransform: 'uppercase',
      textShadow: '2px 2px 0 black',
    },
    clueActive: {
      fontFamily: 'Clue',
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      textShadow: '.1em .1em 0 black',
    },
    jeoparty: {
      fontFamily: 'Jeoparty',
      textAlign: 'center',
      color: 'white',
      textTransform: 'uppercase',
      textShadow: '10px 10px 0 black',
    },
  },
});

export default theme;
