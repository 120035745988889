import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { createContext, useContext, useEffect, useState } from 'react';
import { useFirebase } from './FirebaseContext';
import { onValue, ref } from '@firebase/database';
import { Category, EventTypes, GameEvent, GameState, Player } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';

export type GameContextType = {
  gameId: string | null;
  gameState?: GameState;
  currentPlayer?: Player;
  isLoadingGameState: boolean;
  isActivePlayer: boolean;
  isBuzzedIn: boolean;
  activeCategory?: Category;
  setGameId: (gameId: string) => void;
  createGame: (prompt?: string) => Promise<string>;
  sendEvent: (event: GameEvent) => Promise<HttpsCallableResult<unknown> | void>;
  addPlayer: ({ name, gameId }: { name: string; gameId: string }) => Promise<void>;
  submitAnswer: (event: GameEvent) => Promise<HttpsCallableResult<unknown> | void>;
  loadData: () => void;
  ping: () => NodeJS.Timeout;
};

export const GameContext = createContext<GameContextType>({
  gameId: null,
  isLoadingGameState: true,
  isActivePlayer: false,
  isBuzzedIn: false,
  setGameId: () => {},
  createGame: async () => '',
  sendEvent: async () => {},
  addPlayer: async () => {},
  submitAnswer: async () => {},
  ping: () => setTimeout(() => {}, 0),
  loadData: () => {},
});

export type FunctionRequest = {
  ping?: boolean;
};

export type EventTransitionRequest = {
  gameId: string;
  event: GameEvent;
} & FunctionRequest;

export type AddPlayerRequest = {
  gameId: string;
  name: string;
} & FunctionRequest;

export type CreateGameRequest = {
  prompt?: string;
} & FunctionRequest;

const getCurrentPlayer = (name: string | null, players?: Player[]) => {
  if (!players || !name) return;
  return players.find((player) => player.name === name);
};

const getIsActivePlayer = (currentPlayer?: Player, activePlayer?: Player | null) => {
  if (!currentPlayer || !activePlayer) return false;
  return currentPlayer.name === activePlayer.name;
};

const getIsBuzzedIn = (currentPlayer?: Player, buzzedInPlayer?: Player | null) => {
  if (!currentPlayer || !buzzedInPlayer) return false;
  return currentPlayer.name === buzzedInPlayer.name;
};

export const GameProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const [gameId, setGameId] = useState<string | null>(pathname.replace('/', '') || null);
  const [gameState, setGameState] = useState<GameState>();
  const [isLoadingGameState, setIsLoadingGameState] = useState(!!gameId);
  const { functions, db } = useFirebase();
  const _createGame = httpsCallable<CreateGameRequest, string>(functions, 'createGame');
  const _sendEvent = httpsCallable<EventTransitionRequest>(functions, 'sendEvent');
  const _submitAnswer = httpsCallable<EventTransitionRequest>(functions, 'submitAnswer');
  const _addPlayer = httpsCallable<AddPlayerRequest, Player>(functions, 'addPlayer');
  const _addGamesToFirestore = httpsCallable<FunctionRequest, void>(
    functions,
    'addGameTSVToFirestore',
  );
  const [currentPlayer, setCurrentPlayer] = useState(
    getCurrentPlayer(localStorage.getItem('currentPlayerName'), gameState?.players),
  );
  const navigate = useNavigate();
  const activeCategory = gameState?.categories.find(
    (category) => category.id === gameState.activeClue?.categoryId,
  );

  useEffect(() => {
    if (gameId) {
      navigate(`/${gameId}` || '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  const createGame = async (prompt?: string) => {
    _ping();
    const { data: createdGameId } = await _createGame({ prompt });
    setGameId(createdGameId);
    return createdGameId;
  };

  const sendEvent = async (event: GameEvent) => {
    if (!gameId) return;
    const res = await _sendEvent({ gameId, event });
    return res;
  };

  const submitAnswer = async (event: GameEvent) => {
    if (!gameId) return;
    const res = await _submitAnswer({ gameId, event });
    return res;
  };

  const loadData = async () => {
    await _addGamesToFirestore();
  };

  useEffect(() => {
    setCurrentPlayer(
      getCurrentPlayer(localStorage.getItem('currentPlayerName'), gameState?.players),
    );
  }, [gameState?.players]);

  useEffect(() => {
    const gamesRef = ref(db, 'games');
    const unsubscribe = gameId
      ? onValue(
          gamesRef,
          (snapshot) => {
            const newData = JSON.parse(snapshot.child(gameId).val());
            // TODO: game state loading state, and "does not exist" state
            console.log({ newData });
            if (newData) {
              setGameState({
                currentState: newData.value,
                ...newData.context,
              });
            }
            setIsLoadingGameState(false);
          },
          (err) => {
            console.error({ err });
          },
        )
      : () => {};

    return () => unsubscribe();
  }, [gameId, db]);

  const addPlayer: GameContextType['addPlayer'] = async ({ name, gameId }) => {
    const newPlayer = await _addPlayer({ gameId, name });
    if (newPlayer.data) {
      localStorage.setItem('currentPlayerName', newPlayer.data.name);
      setCurrentPlayer(newPlayer.data);
      setGameId(gameId);
    }
    // TODO: game does not exist
    // TODO: duplicate player
  };
  const _ping = () => {
    _addPlayer({ gameId: '', name: 'ping', ping: true });
    _createGame({ ping: true });
    _sendEvent({ gameId: '', event: { type: EventTypes.PING }, ping: true });
    _submitAnswer({ gameId: '', event: { type: EventTypes.PING }, ping: true });
  };

  const ping = () => {
    _ping();
    return setTimeout(() => {
      _ping();
    }, 50000);
  };

  const isActivePlayer = getIsActivePlayer(currentPlayer, gameState?.activePlayer);
  const isBuzzedIn = getIsBuzzedIn(currentPlayer, gameState?.buzzedInPlayer);

  return (
    <GameContext.Provider
      value={{
        gameId,
        gameState,
        currentPlayer,
        isLoadingGameState,
        isActivePlayer,
        isBuzzedIn,
        activeCategory,
        setGameId,
        createGame,
        sendEvent,
        addPlayer,
        submitAnswer,
        loadData,
        ping,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);
