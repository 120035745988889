import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { useGame } from '../../contexts/GameContext';
import { JeopardyStates } from 'types';
import { SignIn } from './SignIn';
import { Waiting } from './Waiting';
import { CategorySelection } from './CategorySelection';
import { BuzzIn } from './BuzzIn';
import { PlayerAnswering } from './PlayerAnswering';
import { Draw } from './Draw';
import { useMobileOrientation } from 'react-device-detect';
import { motion } from 'framer-motion';
import { ReactComponent as RotatePhone } from './rotate.svg';
import InlineSVG from 'react-inlinesvg';
import { useAnimateScore } from 'hooks/useAnimateScore';
import { TextFit } from 'TextFit';
import { CollectWager } from './CollectWager';

const SVG = InlineSVG as any;

const pageContent = (currentState: JeopardyStates, isLandscape: boolean) => {
  if (isLandscape) {
    return (
      <motion.div
        animate={{
          scale: [1, 1.1, 1],
          opacity: [0.5, 1, 0.5],
          transition: { ease: 'easeInOut', duration: 1.5, repeat: Infinity },
        }}
      >
        <RotatePhone height="50vh" fill="white" />
      </motion.div>
    );
  }
  switch (currentState) {
    case JeopardyStates.WaitingForPlayers:
      return <Waiting />;
    case JeopardyStates.CategorySelection:
      return <CategorySelection />;
    case JeopardyStates.WaitingForBuzz:
      return <BuzzIn />;
    case JeopardyStates.PlayerAnswering:
      return <PlayerAnswering />;
    case JeopardyStates.DailyDoubleWager:
      return <CollectWager />;
    case JeopardyStates.CollectFinalJeopardyWagers:
      return <CollectWager />;
    case JeopardyStates.MakeFinalJeopardyGuesses:
      return <PlayerAnswering />;
    case JeopardyStates.RevealFinalJeopardyAnswer:
      return <Text textStyle="clue">Game Over</Text>;
    default:
      return <Text textStyle="clue">Unknown State</Text>;
  }
};

export const Player: React.FC = () => {
  const { gameState, currentPlayer, activeCategory } = useGame();
  const { isLandscape } = useMobileOrientation();
  const { score } = useAnimateScore(currentPlayer?.score || 0);
  console.log(gameState);

  return (
    <Center height="100%" width="100%" background="jeopardy.blue">
      {currentPlayer && !currentPlayer.nameSvg ? (
        <Draw />
      ) : currentPlayer && gameState ? (
        <Flex
          alignItems="center"
          height="100%"
          width="100%"
          flexDir="column"
          justifyContent="space-between"
        >
          {!isLandscape && (
            <Flex py="10px" alignItems="center" justifyContent="center" gap="12px">
              <Box width="50%">{currentPlayer.nameSvg && <SVG src={currentPlayer.nameSvg} />}</Box>
              <Text width="50%" py="10px" textStyle="category" textAlign="center" fontSize="40px">
                <motion.div>{score}</motion.div>
              </Text>
            </Flex>
          )}
          {[JeopardyStates.WaitingForBuzz, JeopardyStates.PlayerAnswering].includes(
            gameState.currentState,
          ) &&
            activeCategory && (
              <TextFit
                width="80%"
                height="auto"
                textAlign="center"
                as={Center}
                textStyle="category"
              >
                {activeCategory.name}
              </TextFit>
            )}
          <Center width="100%" flexGrow={1}>
            {pageContent(gameState.currentState, isLandscape)}
          </Center>
        </Flex>
      ) : (
        <SignIn />
      )}
    </Center>
  );
};
