import { useEffect, useState } from 'react';
import { animate, useMotionValue, useTransform } from 'framer-motion';

export const useAnimateScore = (playerScore: number) => {
  const [prevScore, setPrevScore] = useState(playerScore);
  const score = useMotionValue(prevScore);
  const rounded = useTransform(score, (value) => `$${Math.round(value).toLocaleString()}`);

  useEffect(() => {
    const animation = animate(score, playerScore, { ease: 'easeOut', duration: 2 });
    setPrevScore(playerScore);
    return animation.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerScore]);

  return {
    score: rounded,
  };
};
