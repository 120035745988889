import { Button, Center, FormControl, FormLabel, Input, Text, VStack } from '@chakra-ui/react';
import { useGame } from '../../contexts/GameContext';
import { GameBoard } from './GameBoard';
import { useEffect, useState } from 'react';

export const Display: React.FC = () => {
  const [customPrompt, setCustomPrompt] = useState('' as string);
  const { createGame, gameState, ping } = useGame();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const pingInterval = ping();
    return () => clearInterval(pingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = async () => {
    setIsLoading(true);
    await createGame(customPrompt);
    setIsLoading(false);
  };

  return (
    <Center width="100%" backgroundColor="jeopardy.blue" height="100%">
      {/* <Text textStyle="clue" position="fixed" top="10px" left="10px" zIndex={9999}>
        {gameState?.gameId} {gameState?.currentState}
      </Text> */}
      {gameState ? (
        <GameBoard />
      ) : (
        <VStack>
          <Text fontSize="10em" textStyle="jeoparty">
            Jeoparty
          </Text>
          <FormControl>
            <FormLabel textStyle="clueActive">Custom game prompt</FormLabel>
            <Input onChange={(e) => setCustomPrompt(e.target.value)} backgroundColor="white" />
          </FormControl>
          <Button isLoading={isLoading} onClick={onCreate} background="jeopardy.lightBlue">
            <Text textStyle="clue">New Game</Text>
          </Button>
        </VStack>
      )}
    </Center>
  );
};
