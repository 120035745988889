import { Center, CenterProps, Flex, Text, VStack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { Player } from 'types';
import InlineSVG from 'react-inlinesvg';
import { motion } from 'framer-motion';
import { useAnimateScore } from 'hooks/useAnimateScore';

const SVG = InlineSVG as any;

export type Props = {
  player: Player;
} & CenterProps &
  PropsWithChildren;

export const PlayerBox: React.FC<Props> = ({ player, children, ...rest }) => {
  const { score } = useAnimateScore(player.score);

  return (
    <Center h="calc(100vh / 4)" w="calc(100vw / 7)" {...rest}>
      <Flex flexDirection="column" gap="12px" alignItems="center">
        {player.nameSvg && <SVG src={player.nameSvg} />}
        <VStack>
          <Text textStyle="clue" fontSize="1vh">
            {player.name}
          </Text>
          <Text textStyle="score">
            <motion.div>{score}</motion.div>
          </Text>
        </VStack>
      </Flex>
    </Center>
  );
};
