import { Button, Center, Flex, FormControl, Text, Textarea } from '@chakra-ui/react';
import { useGame } from 'contexts/GameContext';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { EventTypes, JeopardyStates } from 'types';
import { ReactComponent as Microphone } from './microphone.svg';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';

export const PlayerAnswering: React.FC = () => {
  const { isBuzzedIn, gameState, submitAnswer, currentPlayer } = useGame();
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [answer, setAnswer] = useState('');
  const [isEvaluating, setIsEvaluating] = useState(false);

  useEffect(() => {
    setAnswer(transcript);
  }, [transcript]);

  useEffect(() => {
    setAnswer('');
  }, []);

  const onSubmit = async () => {
    setIsEvaluating(true);
    await submitAnswer({
      type: EventTypes.SUBMIT_ANSWER,
      playerName: currentPlayer!.name,
      answer,
    });
    setIsEvaluating(false);
  };

  const onListen = () => {
    SpeechRecognition.startListening();
  };

  const onStop = () => {
    setTimeout(() => {
      SpeechRecognition.stopListening();
    }, 200);
  };

  const micSize = listening ? '30vh' : '15vh';
  const isFinalJeopardy = gameState!.currentState === JeopardyStates.MakeFinalJeopardyGuesses;
  const playerHasGuessed = currentPlayer!.finalJeopardyResult !== null;

  if (isFinalJeopardy && playerHasGuessed) {
    return (
      <Text p="1em" textAlign="center" textStyle="clue" fontSize="3em">
        Waiting for other players to guess
      </Text>
    );
  }

  return isBuzzedIn || isFinalJeopardy ? (
    <Flex width="100%" userSelect="none" flexDirection="column" gap="25px" alignItems="center">
      {browserSupportsSpeechRecognition && !isFinalJeopardy && (
        <Center
          onMouseDown={onListen}
          onMouseUp={onStop}
          onTouchStart={onListen}
          onTouchEnd={onStop}
        >
          <Microphone fill="white" height={micSize} width={micSize} />
        </Center>
      )}
      <FormControl flexGrow={1} width="80%">
        <Textarea
          fontSize={30}
          height="20vh"
          backgroundColor="white"
          value={capitalize(answer)}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </FormControl>
      <Button isLoading={isEvaluating} onClick={onSubmit} backgroundColor="jeopardy.lightBlue">
        <Text textStyle="clue">Submit</Text>
      </Button>
    </Flex>
  ) : (
    <Text textStyle="clue">{gameState!.buzzedInPlayer!.name} answering</Text>
  );
};
