import { Button, Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import { useGame } from '../../contexts/GameContext';
import { useState } from 'react';
import { lowerCase, toLower } from 'lodash';
import { useLocation } from 'react-router-dom';
import pluralize from 'pluralize';

export const SignIn: React.FC = () => {
  const { pathname } = useLocation();
  const [playerName, setPlayerName] = useState('' as string);
  const [hasError, setHasError] = useState(false);
  const [gameId, setGameId] = useState(pathname.replace('/', '') || '');
  const { gameState, addPlayer } = useGame();

  const onAddPlayer = async () => {
    try {
      await addPlayer({
        name: playerName,
        gameId,
      });
      setHasError(false);
    } catch (e) {
      setHasError(true);
    }
  };

  const playerNameUnique = gameState?.players
    ? gameState?.players.every((player) => lowerCase(player.name) !== lowerCase(playerName))
    : true;

  const isNameInvalid = hasError || !playerNameUnique;

  return (
    <Flex p="4em" height="100%" flexDir="column" gap="19px" justifyContent="center">
      <Text fontSize="4em" flexGrow={1} textStyle="jeoparty">
        JEOPARTY
      </Text>
      <FormControl>
        <FormLabel textStyle="clue">Player Name</FormLabel>
        <Input
          isInvalid={isNameInvalid}
          maxLength={10}
          value={playerName}
          backgroundColor="white"
          onChange={(e) => setPlayerName(e.target.value)}
        />
        {isNameInvalid && (
          <Text textStyle="clue" color="red" fontSize="8px" marginTop="6px">
            Player name already taken
          </Text>
        )}
      </FormControl>
      <FormControl>
        <FormLabel textStyle="clueActive">Game Code</FormLabel>
        <Input
          disabled={pathname !== '/'}
          maxLength={7}
          value={gameId}
          backgroundColor="white"
          onChange={(e) => setGameId(toLower(e.target.value))}
        />
      </FormControl>
      {gameState && (
        <Text textStyle="clue">{pluralize('player', gameState.players.length, true)}</Text>
      )}
      <Button background="jeopardy.lightBlue" onClick={onAddPlayer}>
        <Text textStyle="clue">Join</Text>
      </Button>
    </Flex>
  );
};
