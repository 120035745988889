import { ChakraBaseProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { GameProvider } from './contexts/GameContext';
import theme from './theme';
import Fonts from './theme/fonts/fonts';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Game } from 'Game';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ChakraBaseProvider theme={theme}>
          <Fonts />
          <FirebaseProvider>
            <GameProvider>
              <Routes>
                <Route path="/*" element={<Game />} />
              </Routes>
            </GameProvider>
          </FirebaseProvider>
        </ChakraBaseProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
