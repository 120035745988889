import { Button, Center, Flex, Text } from '@chakra-ui/react';
import { useGame } from '../../contexts/GameContext';
import { EventTypes } from 'types';

export const Waiting: React.FC = () => {
  const { gameState, currentPlayer, sendEvent } = useGame();
  const { activePlayer } = gameState || {};
  const isActivePlayer = currentPlayer?.name === activePlayer?.name;

  const onStartGame = async () => {
    sendEvent({ type: EventTypes.START_GAME });
  };

  return (
    <Center height="100%" width="100%" flexDir="column" gap="20px">
      {isActivePlayer ? (
        <Flex flexDirection="column" gap="12px" width="60%">
          <Text textAlign="center" textStyle="clue">
            You are the host! Start game when ready...
          </Text>
          <Button onClick={onStartGame}>Start Game</Button>
        </Flex>
      ) : (
        <Text textStyle="clue">Waiting for {activePlayer?.name || 'host'} to start the game</Text>
      )}
    </Center>
  );
};
