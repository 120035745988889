import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as BuzzInButton } from './button.svg';
import { ReactComponent as BuzzInButtonPressed } from './button-pressed.svg';
import { useState } from 'react';
import { useGame } from '../../contexts/GameContext';
import { EventTypes } from 'types';

export const BuzzIn: React.FC = () => {
  const { sendEvent, currentPlayer } = useGame();
  const [buttonPressed, setButtonPressed] = useState(false);

  const onPress = async () => {
    setButtonPressed(true);
    await sendEvent({
      type: EventTypes.BUZZ_IN,
      playerName: currentPlayer?.name || '',
      buzzInTime: Date.now(),
    });
  };

  const onRelease = () => {
    setButtonPressed(false);
  };
  return (
    <Center>
      {currentPlayer?.hasBuzzed ? (
        <Text textStyle="clue">You've buzzed in</Text>
      ) : (
        <Flex flexDir="column" gap="20px" alignItems="center">
          <Text textStyle="clue">Buzz in to answer</Text>
          <Box
            onMouseDown={onPress}
            onMouseUp={onRelease}
            onTouchStart={onPress}
            onTouchEnd={onRelease}
          >
            {buttonPressed ? (
              <BuzzInButtonPressed height="50vh" width="50vh" />
            ) : (
              <BuzzInButton height="50vh" width="50vh" />
            )}
          </Box>
        </Flex>
      )}
    </Center>
  );
};
