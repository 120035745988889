import { createContext, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectDatabaseEmulator, getDatabase } from '@firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBedM2TtBRjFkuhIA8Vbk9TQpmAZ8vAS0Y',
  authDomain: 'jeoparty-1bd4f.firebaseapp.com',
  databaseURL: 'https://jeoparty-1bd4f-default-rtdb.firebaseio.com',
  projectId: 'jeoparty-1bd4f',
  storageBucket: 'jeoparty-1bd4f.appspot.com',
  messagingSenderId: '652724219281',
  appId: '1:652724219281:web:fe5904c812175a0224be30',
  measurementId: 'G-7BD8DN2910',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);

export const db = getDatabase(app);

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectDatabaseEmulator(db, 'localhost', 9000);
}

export type FirebaseContextType = {
  functions: ReturnType<typeof getFunctions>;
  db: ReturnType<typeof getDatabase>;
};

export const FirebaseContext = createContext<FirebaseContextType>({
  functions,
  db,
});
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <FirebaseContext.Provider value={{ functions, db }}>{children}</FirebaseContext.Provider>;
};
