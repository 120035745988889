import { Center, CenterProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export type BoardBoxProps = CenterProps & PropsWithChildren;

export const BoardBox: React.FC<BoardBoxProps> = ({ children, ...rest }) => {
  return (
    <Center
      borderColor="black"
      borderStyle="solid"
      borderWidth="0.5vh"
      w={`${100 / 7}vw`}
      h={`${100 / 6}%`}
      flexGrow={1}
      {...rest}
    >
      {children}
    </Center>
  );
};
