import { Box, Text, TextProps } from '@chakra-ui/react';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

type Props = {
  max?: number;
  min?: number;
  ready?: () => void;
} & PropsWithChildren &
  Omit<TextProps, 'fontSize'>;

export const TextFit: React.FC<Props> = ({
  max = 4,
  min = 1,
  ready = () => {},
  children,
  ...rest
}) => {
  const [fontSize, setFontSize] = useState(max);
  const textRef = useRef<HTMLParagraphElement>(null);
  const parentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const container = parentRef.current;
    const text = textRef.current;

    if (!container || !text) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isTooBig = entry.intersectionRatio < 1;
          const modifier = isTooBig ? 0.9 : 1.1;
          if (isTooBig && fontSize > min) {
            setFontSize(fontSize * modifier);
          }
        });
      },
      {
        root: container,
        threshold: 1.0,
      },
    );

    observer.observe(text);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize]);

  return (
    <Box
      overflow="hidden"
      position="relative"
      ref={parentRef}
      h="100%"
      w="100%"
      fontSize={`${fontSize}em`}
      {...rest}
    >
      <Text
        // p="0.5em"
        ref={textRef}
      >
        {children}
      </Text>
    </Box>
  );
};
