import { Center, Flex, Image, Text } from '@chakra-ui/react';
import { useGame } from '../../contexts/GameContext';
import { BoardBox } from './BoardBox';
import { ClueBox } from './Clue';
import { PlayerBox } from './PlayerBox';
import { TextFit } from 'TextFit';
import { AnimatePresence, motion } from 'framer-motion';
import { JeopardyStates, Rounds } from 'types';

const qrCodeBaseUrl = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150';

const clueArray = Array.from({ length: 30 }, (_, i) => (i % 6) + 1);
const clueRenderOrder = clueArray.sort(() => Math.random() - 0.5);

export const GameBoard: React.FC = () => {
  const { gameState, isLoadingGameState } = useGame();
  const qrCodeUrl = `${qrCodeBaseUrl}&data=${window.location.href}`;
  const categories = gameState?.categories;
  const players = gameState?.players;
  const finalJeopardy = [
    JeopardyStates.CollectFinalJeopardyWagers,
    JeopardyStates.MakeFinalJeopardyGuesses,
    JeopardyStates.RevealFinalJeopardyAnswer,
  ].includes(gameState!.currentState);

  const finalJeopardyCategory = categories?.find((c) => c.roundType === Rounds.FinalJeopardy);
  const finalJeopardyClue = finalJeopardyCategory?.clues[0];

  console.log({ categories });

  return (
    <>
      {isLoadingGameState && <Text textStyle="clue">Loading...</Text>}
      <Flex py="50px" height="100%" justifyContent="space-between" direction="column">
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          {players?.length ? (
            players
              ?.sort((a, b) => b.score - a.score)
              .slice(0, 3)
              .map((player) => <PlayerBox key={player.name} player={player} />)
          ) : (
            <Center width="calc(100vw / 7)">
              <Text textStyle="clue">No players</Text>
            </Center>
          )}
        </Flex>
        <Center height="20vh">
          <Image src={qrCodeUrl} alt="QR Code" />
        </Center>
      </Flex>
      {finalJeopardy ? (
        <Center
          backgroundColor={'jeopardy.clueBlue'}
          height="100%"
          flexGrow={1}
          padding={'50px'}
          flexDir="column"
          justifyContent="center"
        >
          <Text as={Center} fontSize="6em" textStyle="jeoparty" textAlign="center">
            FINAL JEOPARTY
          </Text>
          <TextFit flexGrow={1} as={Center} textStyle="clue" textAlign="center">
            {gameState?.currentState === JeopardyStates.CollectFinalJeopardyWagers
              ? finalJeopardyCategory?.name
              : gameState?.currentState === JeopardyStates.MakeFinalJeopardyGuesses
                ? finalJeopardyClue?.question
                : finalJeopardyClue?.answer}
          </TextFit>
        </Center>
      ) : (
        categories
          ?.filter((category) => category.roundType === gameState?.currentRound)
          .map((category, i) => (
            <Flex flexGrow={1} height="100%" width="100%" key={category.id} direction="column">
              <BoardBox borderBottomWidth="2vh">
                <AnimatePresence>
                  <motion.div
                    style={{ height: '100%', width: '100%' }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2, delay: 4 }}
                  >
                    <TextFit
                      max={5}
                      as={Center}
                      padding=".2em"
                      py=".4em"
                      textAlign="center"
                      textStyle="category"
                    >
                      {category.name}
                    </TextFit>
                  </motion.div>
                </AnimatePresence>
              </BoardBox>
              {category.clues.map((clue, j) => {
                return (
                  <ClueBox renderDelay={clueRenderOrder[i * 5 + j]} key={clue.id} clue={clue} />
                );
              })}
            </Flex>
          ))
      )}
    </>
  );
};
