import { Center, Flex, IconButton } from '@chakra-ui/react';
import { useMobileOrientation } from 'react-device-detect';
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas';
import { useRef } from 'react';
import { FaUndoAlt, FaRegWindowClose, FaSave } from 'react-icons/fa';
import { useGame } from 'contexts/GameContext';
import { EventTypes } from 'types';
import { ReactComponent as RotatePhone } from './rotate.svg';
import { motion } from 'framer-motion';

export const Draw = () => {
  const { sendEvent, currentPlayer } = useGame();
  const { isLandscape } = useMobileOrientation();
  const canvasRef = useRef<ReactSketchCanvasRef>(null);

  const onSaveDrawing = async () => {
    const svg = await canvasRef.current?.exportSvg();
    await sendEvent({
      type: EventTypes.SAVE_NAME_DRAWING,
      playerName: currentPlayer!.name || '',
      nameSvg: svg || '',
    });
  };

  return (
    <Center height="100%" width="100%" flexDir="column" gap="12px">
      {isLandscape ? (
        <>
          <ReactSketchCanvas
            ref={canvasRef}
            width="100%"
            height="100%"
            canvasColor="transparent"
            strokeColor="white"
          />
          <Flex position="fixed" top="20px" left="20px" gap="12px">
            <IconButton
              aria-label="undo"
              icon={<FaUndoAlt />}
              onClick={() => canvasRef.current?.undo()}
            />
            <IconButton
              aria-label="clear"
              icon={<FaRegWindowClose />}
              onClick={() => canvasRef.current?.clearCanvas()}
            />
            <IconButton aria-label="save" icon={<FaSave />} onClick={onSaveDrawing} />
          </Flex>
        </>
      ) : (
        <motion.div
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.5, 1, 0.5],
            transition: { ease: 'easeInOut', duration: 1.5, repeat: Infinity },
          }}
        >
          <RotatePhone height="20vh" fill="white" />
        </motion.div>
      )}
    </Center>
  );
};
